body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input:-internal-autofill-selected {
  background-color: none!important;
}

root:-webkit-autofill {
  /* web: '0 0 0 1000px white inset', */
  background-color: red !important
}

input:-webkit-autofill {
  /* web: '0 0 0 1000px white inset', */
  background-color: red !important
}