@import '~normalize.css/normalize.css';
/* * {
  overflow-wrap: inherit;
} */


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.timline-overflow {
  overflow: scroll;
  scrollbar-width: none;
}

.timline-overflow::-webkit-scrollbar { 
  display: none;  
  }


/* Disable  Arrows in type=number in TextField*/

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.Mui-focused {
  outline-color: #2161d1 !important;
  outline-width: 2px !important;
}


.rce-citem{
  background-color: #F9F9F9 !important;
}

.scroll-container {
  overflow: hidden;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 11 */
}

.scroll-container::-webkit-scrollbar {
  width: 0; /* Safari and Chrome */
  height: 0;
}